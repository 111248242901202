<template>
  <div v-if="user" class="setup-phone-number">
    <div class="text-center">
      <b-button
        v-if="!isImpersonating"
        variant="primary"
        class="d-block logout-button"
        @click.prevent="logout"
      >
        <span>LogOut</span>
      </b-button>
    </div>
    <div class="setup-phone-number-inner">
      <div v-if="isImpersonating" class="text-center mb-4">
        <button
          class="btn btn-outline-dark btn-sm"
          :disabled="loadingStopImpersonate"
          @click="stopImpersonate"
        >
          <b-spinner v-if="loadingStopImpersonate" small />
          <i class="uil uil-sign-in-alt"></i> Main account
        </button>
      </div>
      <div class="row">
        <div class="card-wrapper">
          <div class="card">
            <div class="card-body">
              <h4 class="text-center h4">Assign your number</h4>
              <b-tabs content-class="mt-3" fill>
                <b-tab title="Search Number" active>
                  <div class="pt-4">
                    <div class="row">
                      <div class="col-md-12 mb-4">
                        <span
                          class="
                            px-3
                            d-block
                            font-size-16
                            text-center text-primary
                            font-bold
                          "
                          >{{ searchLabel }}</span
                        >
                      </div>
                      <div
                        v-if="
                          countryCode && !['US', 'CA'].includes(countryCode)
                        "
                        class="col-md-12 mb-4"
                      >
                        <b-alert
                          class="err-message text-center"
                          show
                          variant="warning"
                          >For text messaging it is always best practices to use
                          numbers within the same country as the end user's
                          device. We can't guarantee users can receive text
                          messages from countries outside of
                          <span
                            ><b>{{ twilioNumberCountry }}</b></span
                          >
                          (a different country than your marketing
                          number).</b-alert
                        >
                      </div>
                      <div class="col-md-12 col-sm-12">
                        <PhoneInput
                          v-model="searchParams.regex"
                          name="regex"
                          label="Search by digits or phrases"
                          :default-country="defaultCountry"
                          :allow-char="true"
                          @selectedCountry="handleSelectedCountry"
                          @onInputChange="onInputChange"
                          @onCountryChange="onCountryChange"
                        />
                      </div>
                      <div v-if="false" class="col-md-6 col-sm-12">
                        <SelectInput
                          v-model="searchParams.match_to"
                          label="Match to"
                          name="match_to"
                          :options="matchToOptions"
                          :disable-search="true"
                          :required-value="true"
                        />
                      </div>
                      <div class="col-md-12 col-sm-12 text-center">
                        <b-button
                          variant="primary"
                          class="d-block w-100 search-button"
                          :disabled="loadingSearch || !searchParams.regex"
                          @click="handleSearch"
                        >
                          <b-spinner v-if="loadingSearch" small />
                          <span v-else>Search</span>
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div class="row text-center mt-4">
                    <div
                      v-for="(phone, index) in phones"
                      :key="`phone-${index}`"
                      class="col-12"
                    >
                      <div class="custom-control custom-radio mb-4">
                        <input
                          :id="`phone-${index}`"
                          type="radio"
                          name="phone"
                          class="custom-control-input"
                          :value="phone.phone_number"
                          @input="onNumberSelect(index)"
                        />
                        <label
                          class="custom-control-label"
                          :for="`phone-${index}`"
                        >
                          <span class="font-weight-bold float-left">{{
                            phone.phone_number
                          }}</span>
                          <span v-if="phone.price" class="pl-4 float-left">
                            {{ phone.price_unit }}:
                            {{ phone.price.current_price }}
                          </span>
                          <span
                            v-if="phone.number_type === 'toll free'"
                            class="pl-4 text-uppercase float-left"
                            >( {{ phone.number_type }} )</span
                          >
                          <span
                            v-if="phone.address_requirements !== 'none'"
                            v-b-tooltip.hover
                            v-b-tooltip.hover.top
                            title="Address Verification Required!"
                            class="pl-4 number-address float-left text-primary"
                            ><feather type="book"></feather
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div v-if="phones !== null && !phones.length">
                    <div class="alert alert-danger">
                      <span
                        v-if="!user.use_bandwidth"
                        >There are no phone numbers available on Twilio for the
                        area code you are searching for. You can always request
                        a phone number directly from Twilio by following this
                        link
                        <a
                          target="_blank"
                          href="https://www.twilio.com/console/form/number-request"
                          class="text-white text-underline"
                          ><u
                            >https://www.twilio.com/console/form/number-request</u
                          ></a
                        ></span
                      >
                      <span v-else>There are no phone numbers available for the
                        area code you are searching for</span>
                    </div>
                  </div>
                  <div
                    v-if="
                      address &&
                      selectedPhoneIndex !== null &&
                      phones[selectedPhoneIndex].address_requirements !== 'none'
                    "
                  >
                    <fieldset>
                      <h4 class="text-center">Address Information</h4>
                      <div class="row">
                        <div class="col-md-6">
                          <text-input
                            v-model="address.street"
                            name="Street"
                            label="Street"
                            placeholder="Street"
                            required
                          />
                        </div>
                        <div class="col-md-6">
                          <text-input
                            v-model="address.city"
                            name="City"
                            label="City"
                            placeholder="City"
                            required
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <text-input
                            v-model="address.region"
                            name="Region"
                            label="Region"
                            placeholder="Region"
                            required
                          />
                        </div>
                        <div class="col-md-6">
                          <text-input
                            v-model="address.postal_code"
                            name="PostalCode"
                            label="Postal Code"
                            placeholder="Postal Code"
                            required
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div v-if="errorMsg" class="col-md-12 my-4">
                    <b-alert
                      class="err-message text-center"
                      show
                      variant="danger"
                      >{{ errorMsg }}</b-alert
                    >
                  </div>
                  <div
                    v-if="selectedPhoneIndex !== null"
                    class="text-center mt-4"
                  >
                    <button
                      class="btn btn-primary w-50"
                      :disabled="loadingAssign || !assignValid"
                      @click="handleAssign"
                    >
                      <b-spinner v-if="loadingAssign" small />
                      <span v-else>Assign</span>
                    </button>
                  </div>
                </b-tab>
                <b-tab v-if="user.business && user.business.agency" title="Custom Number">
                  <ValidationObserver
                    v-slot="{ invalid, touched }"
                    ref="assignForm"
                  >
                    <div class="row">
                      <div class="col-md-12 mb-4">
                        <span
                          class="
                            px-3
                            d-block
                            font-size-16
                            text-center text-primary
                            font-bold
                          "
                          >Please enter the custom number and press assign</span
                        >
                      </div>
                      <div class="col-md-9 col-sm-12">
                        <PhoneInput
                          v-model="customPhonenumber"
                          name="phone_number"
                          label="Twilio phone number"
                          rules="required"
                          :default-country="defaultCountry"
                        />
                      </div>
                      <div class="col-md-3 col-sm-12 mt-2">
                        <div class="form-group">
                          <label class="form-control-label"></label>
                          <b-button
                            variant="primary"
                            class="d-block w-100 search-button"
                            :disabled="(invalid && touched) || loadingAssign"
                            @click="handleAssignCustomPhonenumber(false)"
                          >
                            <b-spinner v-if="loadingAssign" small />
                            <span v-else>Assign</span>
                          </b-button>
                        </div>
                      </div>
                      <div v-if="errorCustomMsg" class="col-md-12 my-4">
                        <span
                          class="
                            px-3
                            d-block
                            font-size-15
                            text-center text-danger
                            font-bold
                          "
                          >{{ errorCustomMsg }}</span
                        >
                        <div class="form-group">
                          <label class="form-control-label"></label>
                          <b-button
                            variant="primary"
                            class="d-block w-100 search-button"
                            :disabled="(invalid && touched) || loadingAssign"
                            @click="handleAssignCustomPhonenumber(true)"
                          >
                            <b-spinner v-if="loadingAssign" small />
                            <span v-else>Proceed</span>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </ValidationObserver>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countryCode: '',
      loadingAssign: false,
      loadingStopImpersonate: false,
      loadingSearch: false,
      phones: null,
      selectedPhoneIndex: null,
      customPhonenumber: null,
      searchParams: {
        country: null,
        regex: '',
        match_to: 'First',
      },
      errorCustomMsg: null,
      errorMsg: null,
      address: null,
    }
  },

  computed: {
    searchLabel() {
      return 'Please enter digits or phrases of the number you are looking for and then press search.';
    },
    countries() {
      return this.$store.getters['app/countryWithCode']
    },

    twilioNumberCountry() {
      return this.countries[this.countryCode]
    },

    assignValid() {
      if (
        this.selectedPhoneIndex !== null &&
        this.phones[this.selectedPhoneIndex].address_requirements !== 'none'
      )
        return (
          this.address.street &&
          this.address.city &&
          this.address.region &&
          this.address.postal_code
        )
      return this.selectedPhoneIndex !== null
    },

    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    defaultCountry() {
      if (this.user && this.user.business) return this.user.business.country
      return ''
    },

    matchToOptions() {
      return [
        { label: 'First part of number', value: 'First' },
        { label: 'Anywhere in number', value: 'Anywhere' },
        { label: 'Last part of number', value: 'Last' },
      ]
    }
  },

  mounted() {
    if (this.user && this.user.business.phone_number) {
      this.$router.push({ name: 'business.dashboard' })
    }
  },

  methods: {
    initAddress() {
      this.address = {
        street: '',
        city: '',
        region: '',
        postal_code: '',
        country_code: '',
      }
    },

    onInputChange(args) {
      this.countryCode = args.regionCode
    },

    onCountryChange(country) {
      this.countryCode = country
    },

    onNumberSelect(index) {
      this.selectedPhoneIndex = index
      if (
        this.phones[this.selectedPhoneIndex].address_requirements !== 'none'
      ) {
        this.initAddress()
      }
    },
    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
        .dispatch('auth/stopImpersonate')
        .then(() => {
          this.$store
            .dispatch('auth/getProfile')
            .then((newuser) => {
              if (newuser.role === 'Agency') {
                this.$router.push('/agency')
              } else if (newuser.role === 'Admin') {
                this.$router.push('/admin')
              }
              this.loadingStopImpersonate = false
            })
            .catch(() => {
              this.loadingStopImpersonate = false
            })
        })
        .catch(() => {
          this.loadingStopImpersonate = false
        })
    },

    handleSearch() {
      this.errorCustomMsg = null
      this.errorMsg = null
      this.phones = null
      this.loadingSearch = true
      this.selectedPhoneIndex = null

      this.$store
        .dispatch('business/searchNumbers', this.searchParams)
        .then((phones) => {
          this.phones = phones
          this.loadingSearch = false
        })
        .catch(() => {
          this.loadingSearch = false
        })
    },

    handleAssign() {
      this.errorCustomMsg = null
      this.errorMsg = null
      this.loadingAssign = true

      const payload = {
        phone_number: this.phones[this.selectedPhoneIndex].phone_number,
      }

      if (
        this.phones[this.selectedPhoneIndex].address_requirements !== 'none'
      ) {
        payload['address'] = this.address
        payload['address']['country_code'] = this.countryCode
      }

      this.$store
        .dispatch('business/assignNumber', payload)
        .then(() => {
          this.loadingAssign = false
          this.$store.dispatch('auth/getProfile')
          this.$router.push({ name: 'business.dashboard' })
        })
        .catch((error) => {
          this.errorMsg = error.response.data.message
          this.loadingAssign = false
        })
    },

    handleAssignCustomPhonenumber(force = false) {
      this.errorCustomMsg = null
      this.loadingAssign = true

      const params = {
        phone_number: this.customPhonenumber,
        force: force,
      }
      this.$store
        .dispatch('business/assignCustomNumber', params)
        .then(() => {
          this.loadingAssign = false
          this.$router.push({ name: 'business.dashboard' })
        })
        .catch((error) => {
          if (error.response && error.response.status === 406) {
            this.errorCustomMsg = error.response.data.message
          }
          this.loadingAssign = false
        })
    },

    handleSelectedCountry(country) {
      this.searchParams.country = country
    },

    logout() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.setup-phone-number-inner {
  .card-wrapper {
    width: 100%;
    max-width: 600px;
    margin: auto;
  }
  .logout-button {
    max-width: 200px;
    position: absolute;
    top: 50px;
    right: 50px;
  }
  .search-button {
    max-width: 200px;
    margin: auto;
  }
  .custom-control {
    z-index: 0;
  }
}
</style>
